@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-Thin.ttf');
  font-weight: 100;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-ExtraLight.ttf');
  font-weight: 200;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-Normal.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-ExtraBold.ttf');
  font-weight: 800;
}

body {
  margin: 0;
  font-family: 'HelveticaNeue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FBFBFB;
}
h2 {
  font-weight: 700;
}
select {
  background-color: white;
  font-size: 1rem;
  color: #3BDDCA;
  padding: 0.5rem;
}
textarea:focus, input:focus, select:focus{
  outline: none;
}
.date-button {
  color: #3BDDCA;
  font-size: 1rem;
  font-weight: bold;
}
.date-picker input {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  width: 100%;
  padding: 0.94rem 1rem;
  border: 1px solid #C4C4C4;
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.6);
  background: #FBFBFB;
}
.red-border input {
  border: 1px solid red;
}
.react-datepicker-popper {
  z-index: 1300 !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
}
.react-datepicker {
  width: 100% !important;
  border: 1px solid white !important;
  display: flex !important;
}
.react-datepicker__header {
  background-color: white !important;
  border-bottom: 1px solid white !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #3BDDCA !important;
  font-size: 1.3rem  !important;;
}
.react-datepicker__navigation {
  top: 15px !important;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #3BDDCA !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  display: none !important;
  margin: 0.3rem 0 !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  display: none !important;
  margin: 0.3rem 0 !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #3BDDCA !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: white !important;
  color: black !important;
}
.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  border-radius: 0.3rem !important;
  color: white !important;
  background-color: #478DFF !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2.5rem !important;
  font-size: 0.9rem !important;
}
.react-datepicker__day-name {
  color: #7D7D80 !important;
  font-size: 1.1rem !important;
  font-weight: bolder !important;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #034752 !important;
  font-weight: bold !important;
  background-color: #F1F2F2 !important;
  border-radius: 0.3rem !important;
}
.react-datepicker__day--highlighted {
  color: #034752 !important;
  font-weight: bold !important;
  background-color: #F1F2F2 !important;
  border-radius: 0.3rem !important;
}
.react-datepicker__month-container {
  width: 100% !important;
}
.react-datepicker__day-names, .react-datepicker__week {
  display: flex !important;
  justify-content: space-around !important;
}
.react-datepicker__input-container input[type="text"]:disabled  {
  color: rgba(0, 0, 0, 0.4) !important;
}
